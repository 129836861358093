<template>
  <div>
    <MessagingArea height="auto" :selectedChat="selectedChannelChat" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MessagingArea from './components/MessagingArea.vue';

export default {
  components: { MessagingArea },
  computed: {
    ...mapState('messaging', {
      selectedChannelChat: (state) => state.selectedChannelChat,
    }),
  },
};
</script>
